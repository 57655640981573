import React from "react"
import SEO from "../components/seo"
import Layout from "../layout/layout"
import AppFunctionComponent from "@appnroll/app-function-component"

const NotFoundPage: AppFunctionComponent = () => (
  <Layout>
    <SEO title="404: Not found" image={""} />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
